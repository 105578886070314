form {
  button, input[type=submit] {
      background-color: $color-primary;
      color: white;
      padding: 15px 20px;
      margin-top: 60px;
      border-radius: 3px;
      overflow: hidden;
      position: relative;
      
      &:after {
        background: #fff;
        content: "";
        height: 155px;
        left: -75px;
        opacity: .2;
        position: absolute;
        top: -50px;
        transform: rotate(35deg);
        transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
        width: 50px;
      }
      &:hover {
          cursor: pointer;
          &:after {
              left: 120%;
              transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
          }
      }
      i {
          margin-right: 10px;
      }
      p {
          line-height: 12px;
      }
      span {
        z-index: 20;
      }
  }

  &.download-pdf, &.share-page {
    width: 350px;
    z-index: 10;
    position: fixed;
    margin: auto;
    left: 0;
    right: 0;
    background: $gray-light;
    padding: 20px;
    top: 50%;
    transform: translateY(-50%);
    display: none;
    font-family: $font-family--secondary;
    button {
      margin-top: 20px;
      font-family: $font-family--primary; 
      font-size: 23px;
    }
    input, textarea {
      margin-left: 0;
      margin-right: 0;
      font-family: $font-family--secondary;
    }
    .fa-close:hover {
      cursor: pointer;
    }
  }
  .form-error {
      display: block;
      font-size: 12px;
      color: $color-primary; 
      font-weight: bold;
  }
  .form-success {
    text-align: center;
    font-family: $font-family--secondary;
    margin-top: 10px;
  }
  input, textarea {
    background-color: $white;
    padding: 10px;
    margin: 10px;
    width: 50%;
  }

}
