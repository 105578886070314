footer {
    background-color: $color-secondary;
    padding: 20px;
    padding-bottom: 0;
    font-size: 16px;
    color: $white;
    z-index: 101;
    position: relative;
    .copyright, .credit {
        background-color: $color-primary;
        padding: 5px;
    }
    .links {
        text-align: center;
        background-color: #231F20;
        padding-bottom: 20px;
        @media screen and (min-width: $small-width) {
            padding-bottom: 0;
        }
        img {
            margin: 5px;
            margin-top: 10px;
            width: 250px;
             @media screen and (min-width: $small-width) {
                 width: 150px;
             }
            &:last-of-type {
                width: 130px;
                margin-top: 0;
            }
        }
        p {
            color: $gray-base;
            font-family: $font-family--secondary;
        }
    }
    .logo {
        max-width: 250px;
        margin-top: 20px;
        margin-bottom: 20px;
        @media screen and (min-width: $small-width) {
            max-width: 450px;
        }
    }
   .slogan {
        font-size: 30px;
   }
    small {
        font-family: $font-family--secondary;
    } 
}