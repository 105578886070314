main {
  .mobile-overlay {
    background: rgba(0,0,0,.85);
    display: none;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
  } 
}