.banner {
    height: 300px;
    border-bottom: 20px solid #2D68A5;
    background-color: white;
     @media screen and (min-width: $small-width) {
        height: 500px;
    }
    @media screen and (min-width: $medium-width) {
        height: 750px;
    }
    button {
        top: -40px;
        position: relative;
        @media screen and (min-width: $small-width) {
            top: -80px;
        }
    }
    .image {
        background-image: url('/dist/images/banner-foreground.jpg');
        background-repeat: no-repeat;
        background-position: 0;
        background-color: $color-primary;
        @extend %transition-slow; 
        @media screen and (min-width: $medium-width) {
            background-position: 0 60px;
        }
        @media screen and (min-width: $medium-width) {
            background-position: 1920px 102px;
        }
        img {
            width: 100%;
            margin-top: 60px;
             @media screen and (min-width: $medium-width) {
                z-index: 2;
                opacity: 0;
                margin-top: 130px;
            } 
        }
    }
    &--subtext{
        background-color: $color-secondary;
        padding: 20px 0; 
        z-index: 3;
        position: relative;
        @media screen and (min-width: $small-width) {
            padding: 50px 0; 
        }
        h2 {
            color: white;
            font-size: 16px;
            text-align: center;
            
            @media screen and (min-width: $small-width) {
                font-size: 25px;
            }
             @media screen and (min-width: $medium-width) {
                font-size: 50px;
                line-height: 50px;
            }
        }
    }
    &--text{
        height: 150px;
        left: -21px;
        margin-top: -27px;
        background-position-x: right;
        @media screen and (max-width: 1600px) {
            left: 0;
            background-position-x: -700px;  
        }
        h1 {
          color: white;
            font-size: 16px;
            text-align: left;
            padding-right: 160px;
            padding-left: 40px;
            font-weight: normal;
            text-transform: uppercase;
            margin-top: 36px;
            font-weight: bold;
            @media screen and (min-width: $small-width) {
                font-size: 25px;
            }
             @media screen and (min-width: $medium-width) {
                font-size: 57px;
                margin-top: -80px;
                padding-right: 100px;
            }  
             @media screen and (max-width: 1600px) {
                font-size: 57px;
                margin-top: -80px;
                padding-right: 100px;
            }
        }

    }
} 