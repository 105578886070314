.mega-menu {
    position: fixed;
    z-index: 4;
    top: 0;
    background-color: $gray-xlight;
    width: 100%;
    box-shadow: 5px 10px 10px rgba(0,0,0,.3);
    margin-top: -280px;
    @extend %transition-slow;
    display: none;
     @media screen and (min-width: $medium-width) { 
        display: block;
    }
    a:hover {
        text-decoration: none;
    }
    .box--image {
        height: 200px;
    }
    .container div:not(:last-of-type){
        border-right: 1px solid lightgray;
    }
    img {
        height: 155px;
          @extend %transition-slow;
        &:hover {
            height: 165px;
            @extend %transition-slow;
        }
     }
     &.slide-up {
        margin-top: 60px;
        @extend %transition-base;
     }
      &.slide-down {
        margin-top: 110px;
        @extend %transition-slow;
     }
}
nav {
     @media screen and (max-width: $medium-width) { 
        background-color: $color-secondary;
    }
}